<template>
  <v-app>
    <v-sheet color="green darken-4" dark min-height="70">
      <v-container>
        <v-row class="py-3">

          <v-img style="cursor: pointer;"  @click="$router.push('/').catch(()=>{})" :src="require('@/assets/apa2.png')" position="left" max-height="60" min-width="200" contain></v-img>
          <v-spacer></v-spacer>
          <v-btn text large dark  to="/" ><v-icon>mdi-list-box-outline</v-icon> List</v-btn>

          <v-btn text large dark  to="/login" v-if="!$store.getters.isLoggedIn"><v-icon>mdi-login</v-icon> Sign in</v-btn>
          <v-btn text large dark  @click="logout" v-if="$store.getters.isLoggedIn"><v-icon>mdi-logout</v-icon> Sign out</v-btn>
        </v-row>
      </v-container>
    </v-sheet>
    <loader-component></loader-component>
    <v-main>
      <router-view></router-view>
    </v-main>


    <v-footer
            dark
            padless
    >
      <v-card
              class="flex"
              flat
              tile
      >


        <v-card-text class="py-2 white--text text-center">
          {{ new Date().getFullYear() }} — <strong>APA</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>

  import LoaderComponent from "@/components/LoadingComponent";
  export default {
    name: 'App',
    components: {LoaderComponent},
    data: () => ({
      //
    }),
    methods:{
      async logout() {
        await this.$store.dispatch('logout')

      },
    }
  };
</script>
