<template>

    <div class="booking pt-5 container">
        <v-row class="fill-height">
            <v-col cols="12" md="3">
                <v-snackbar
                        v-model="error_data.status" :type="error_data.type"
                >
                    {{ error_data.msg }}

                    <template v-slot:action="{ attrs }">
                        <v-btn
                                color="error"
                                text
                                v-bind="attrs"
                                @click="snackbar = false"
                        >
                            Close
                        </v-btn>
                    </template>
                </v-snackbar>
                <v-card flat>
                    <v-toolbar dense flat color="grey lighten-3" height="64">
                        <v-toolbar-title>Today's</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-4" dark v-if="$store.getters.isLoggedIn" @click="add_dialog=true">
                            <v-icon>mdi-plus</v-icon>
                            New
                        </v-btn>

                    </v-toolbar>
                    <v-list>
                        <template v-for="(item,i) in today_events">
                            <v-list-item dense :key="i">
                                <v-list-item-content>
                                    <v-list-item-title> {{item.title}}</v-list-item-title>
                                    <v-list-item-subtitle>{{item.description}}</v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <v-icon small>mdi-account</v-icon>
                                        {{item.first_name}}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <v-icon small>mdi-clock-time-eight-outline</v-icon>
                                        {{item.start_date | moment('hh:mm A') }} -  {{item.end_date | moment('hh:mm A') }} <v-spacer></v-spacer>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider :key="'d'+i"></v-divider>
                        </template>
                    </v-list>

                </v-card>
            </v-col>

            <v-col cols="12" md="9">
                <v-sheet height="64">
                    <v-toolbar
                            flat color="grey lighten-3"
                    >
                        <v-btn
                                outlined
                                class="mr-4"
                                color="grey darken-2"
                                @click="setToday"
                        >
                            Today
                        </v-btn>
                        <v-btn
                                fab
                                text
                                small
                                color="grey darken-2"
                                @click="prev"
                        >
                            <v-icon small>
                                mdi-chevron-left
                            </v-icon>
                        </v-btn>
                        <v-btn
                                fab
                                text
                                small
                                color="grey darken-2"
                                @click="next"
                        >
                            <v-icon small>
                                mdi-chevron-right
                            </v-icon>
                        </v-btn>
                        <v-toolbar-title v-if="$refs.calendar">
                            {{ $refs.calendar.title }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn-toggle
                                v-model="type"
                                dense
                                active-class="warning white--text"
                        >
                            <v-btn value="day">
                                Day
                            </v-btn>

                            <v-btn value="week">
                                Week
                            </v-btn>

                            <v-btn value="month">
                                Month
                            </v-btn>

                        </v-btn-toggle>

                    </v-toolbar>
                </v-sheet>
                <v-sheet height="700">
                    <v-calendar
                            ref="calendar"
                            v-model="focus"
                            color="primary"
                            :events="events"
                            :first-interval="7"
                            :interval-count="12"
                            :event-color="getEventColor"
                            :type="type"
                            @click:event="showEvent"
                            @click:more="viewDay"
                            @click:date="viewDay"
                            @click:time="showAddDialog"
                            @change="getEvent"

                    ></v-calendar>
                    <v-menu
                            v-model="selectedOpen"
                            :close-on-content-click="false"
                            :activator="selectedElement"
                            offset-x
                    >
                        <v-card
                                color="grey lighten-4"
                                min-width="350px"
                                flat
                        >
                            <v-toolbar
                                    :color="selectedEvent.color"
                                    dark
                                    flat
                                    dense
                            >
                                <v-toolbar-title >{{selectedEvent.name}} </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="selectedOpen = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-toolbar>

                            <v-card-text>
                                {{selectedEvent.details}}

                            </v-card-text>

                            <v-card-text>
                                <v-chip small> {{selectedEvent.start | moment('hh:mm A') }} -  {{selectedEvent.end | moment('hh:mm A') }}</v-chip>
                            </v-card-text>
                            <v-divider class="mx-4"></v-divider>
                            <v-card-actions >
                                <v-icon>mdi-account</v-icon>
                                {{selectedEvent.user}}
                                <v-spacer></v-spacer>
                                <v-btn
                                        icon
                                        title="Edit"
                                        color="warning"
                                        @click="updateBooking(selectedEvent)"
                                        v-if="$store.getters.isLoggedIn"
                                >
                                    <v-icon>mdi-pencil-box-outline</v-icon>
                                </v-btn>

                                <v-btn
                                        icon
                                        title="Delete"
                                        color="error"
                                        @click="deleteEvent"
                                        v-if="$store.getters.isLoggedIn"
                                >
                                    <v-icon>mdi-delete-forever</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-menu>
                </v-sheet>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-dialog
                    v-model="add_dialog"
                    persistent
                    max-width="600px"
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">New Booking</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-alert
                                    dense
                                    type="error"
                                    v-model="error_data.status"
                                    dismissible
                            >
                                {{error_data.msg}}
                            </v-alert>
                            <v-form ref="booking_form">
                                <v-row>


                                    <v-col cols="12">
                                        <v-text-field
                                                label="Title*"
                                                v-model="add_form_data.title"
                                                :rules="required_rule"
                                                required outlined dense
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-textarea
                                                label="Description*"
                                                rows="3"
                                                v-model="add_form_data.description"
                                                :rules="required_rule"
                                                outlined
                                                dense
                                        ></v-textarea>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-datetime-picker
                                                v-model="add_form_data.start_date"
                                                dateFormat="dd/MM/yyyy"
                                                :text-field-props="{outlined:true,dense:true,rules:required_rule}"
                                                :time-picker-props="{format:'24hr'}"
                                                :date-picker-props="{min:minDate}"
                                                label="From Date*">
                                            <template slot="dateIcon">
                                                <v-icon>mdi-calendar</v-icon>
                                            </template>
                                            <template slot="timeIcon">
                                                <v-icon>mdi-clock-outline</v-icon>
                                            </template>
                                        </v-datetime-picker>
                                    </v-col>

                                    <v-col cols="6">
                                        <v-datetime-picker
                                                label="Until Date*"
                                                type="date"
                                                dateFormat="dd/MM/yyyy"
                                                v-model="add_form_data.end_date"
                                                :time-picker-props="{format:'24hr'}"
                                                :date-picker-props="{min:minDate}"
                                                :text-field-props="{outlined:true,dense:true,rules:required_rule}"
                                        >
                                            <template slot="dateIcon">
                                                <v-icon>mdi-calendar</v-icon>
                                            </template>
                                            <template slot="timeIcon">
                                                <v-icon>mdi-clock-outline</v-icon>
                                            </template>
                                        </v-datetime-picker>
                                    </v-col>

                                    <v-item-group class="d-flex d-inline justify-space-between"
                                                  mandatory  v-model="selected_color" >
                                        <v-col cols="1" v-for="(row,i) in colors" :key="i" >

                                            <v-item v-slot="{ active, toggle }"  >
                                                <v-btn :color="row"

                                                       class="d-flex align-center"
                                                       dark
                                                       @click="toggle"
                                                >
                                                    <v-scroll-y-transition>
                                                        <v-icon
                                                                v-if="active"
                                                        >
                                                            mdi-check-circle-outline
                                                        </v-icon>
                                                    </v-scroll-y-transition>
                                                </v-btn>
                                            </v-item>
                                        </v-col>
                                    </v-item-group>
                                    <v-col>
                                        Duration: {{duration}}
                                    </v-col>

                                </v-row>
                            </v-form>

                        </v-container>

                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                color="error"
                                text
                                @click="add_dialog = false"
                        >
                            Close
                        </v-btn>
                        <v-btn
                                color="green darken-4"
                                text
                                @click="saveBooking"
                        >
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog
                    v-model="update_dialog"
                    persistent
                    max-width="600px"
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Update Booking</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-alert
                                    dense
                                    type="error"
                                    v-model="error_data.status"
                                    dismissible
                            >
                                {{error_data.msg}}
                            </v-alert>
                            <v-form ref="update_form">
                                <v-row>


                                    <v-col cols="12">
                                        <v-text-field
                                                label="Title*"
                                                v-model="update_form_data.title"
                                                :rules="required_rule"
                                                required outlined dense
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-textarea
                                                label="Description*"
                                                rows="3"
                                                v-model="update_form_data.description"
                                                :rules="required_rule"
                                                outlined
                                                dense
                                        ></v-textarea>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-datetime-picker
                                                v-model="update_form_data.start_date"
                                                dateFormat="dd/MM/yyyy"
                                                :text-field-props="{outlined:true,dense:true,rules:required_rule}"
                                                :time-picker-props="{format:'24hr'}"
                                                :date-picker-props="{min:minDate}"
                                                label="From Date*">
                                            <template slot="dateIcon">
                                                <v-icon>mdi-calendar</v-icon>
                                            </template>
                                            <template slot="timeIcon">
                                                <v-icon>mdi-clock-outline</v-icon>
                                            </template>
                                        </v-datetime-picker>
                                    </v-col>

                                    <v-col cols="6">
                                        <v-datetime-picker
                                                label="Until Date*"
                                                type="date"
                                                dateFormat="dd/MM/yyyy"
                                                v-model="update_form_data.end_date"
                                                :time-picker-props="{format:'24hr'}"
                                                :date-picker-props="{min:minDate}"
                                                :text-field-props="{outlined:true,dense:true,rules:required_rule}"
                                        >
                                            <template slot="dateIcon">
                                                <v-icon>mdi-calendar</v-icon>
                                            </template>
                                            <template slot="timeIcon">
                                                <v-icon>mdi-clock-outline</v-icon>
                                            </template>
                                        </v-datetime-picker>
                                    </v-col>


                                    <v-col>
                                        Duration: {{updateDuration}}
                                    </v-col>

                                </v-row>
                            </v-form>

                        </v-container>

                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                color="error"
                                text
                                @click="update_dialog = false"
                        >
                            Close
                        </v-btn>
                        <v-btn
                                color="green darken-4"
                                text
                                @click="updateSave"
                        >
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>

    export default {
        name: 'HomeView',
        data: () => ({
            focus: '',
            minDate:new Date().toISOString().substr(0, 10),
            currentTime:new Date().toLocaleTimeString(),
            type: 'week',
            typeToLabel: {
                month: 'Month',
                week: 'Week',
                day: 'Day',
            },
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
            events: [],
            colors: ['green', 'blue', 'indigo', 'deep-purple',  'orange','red'],
            today_events: [],
            add_dialog: false,
            update_dialog: false,
            selected_color:0,
            add_form_data: {
                title: '',
                description: '',
                start_date: '',
                end_date: '',
                color: 'green'
            },
            required_rule: [
                v => !!v || 'required'
            ],
            error_data: {
                status: false,
                type: 'error',
                msg: ''
            },
            update_form_data: {
                b_id: '',
                title: '',
                description: '',
                start_date: '',
                end_date: '',
                color: 'green'
            },
        }),
        computed: {
            duration() {

                // Calculate the difference in milliseconds
                let diffInMs = Math.abs(this.add_form_data.end_date - this.add_form_data.start_date);

                // Calculate the duration in hours
                let diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));

                // Output the duration in hours
                return diffInHours + ' hours'
            },
            updateDuration(){
                // Calculate the difference in milliseconds
                let diffInMs = Math.abs(this.update_form_data.end_date - this.update_form_data.start_date);

                // Calculate the duration in hours
                let diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));

                // Output the duration in hours
                return diffInHours + ' hours'
            }
        },
        mounted() {
            this.$refs.calendar.checkChange()
        },
        methods: {
            viewDay({date}) {
                this.focus = date
                this.type = 'day'
            },
            getEventColor(event) {
                return event.color
            },
            setToday() {
                this.focus = ''
            },
            prev() {
                this.$refs.calendar.prev()
            },
            next() {
                this.$refs.calendar.next()
            },
            showEvent({nativeEvent, event}) {
                const open = () => {
                    this.selectedEvent = event
                    this.selectedElement = nativeEvent.target
                    requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
                }

                if (this.selectedOpen) {
                    this.selectedOpen = false
                    requestAnimationFrame(() => requestAnimationFrame(() => open()))
                } else {
                    open()
                }

                nativeEvent.stopPropagation()
            },

            rnd(a, b) {
                return Math.floor((b - a + 1) * Math.random()) + a
            },
            async getEvent() {
                try {
                    const r = await window.axios.get('booking')

                    const {today, all} = r.data

                    // console.log(r.data)

                    const events = []

                    all.forEach((item) => {
                        events.push({
                            id: item.b_id,
                            name: item.title,
                            start: item.start_date,
                            end: item.end_date,
                            details: item.description,
                            user: item.first_name,
                            color: item.color,

                        })

                    })


                    this.events = events
                    this.today_events = today

                    // console.log(this.events)
                } catch (e) {
                    console.log(e)
                }
            },
            async saveBooking() {
                try {
                    if (!this.$refs.booking_form.validate()) {
                        return
                    }

                    const check = this.checkOverlap(this.add_form_data.start_date, this.add_form_data.end_date)

                    if (!check) {
                        this.error_data.status = true
                        this.error_data.msg = 'Event Overlap with others, Please adjust your time'
                        return
                    }
                    this.add_form_data.color=this.colors[this.selected_color]
                    // console.log(this.add_form_data)
                    await window.axios.post('booking/create', this.add_form_data)
                    this.add_dialog = false
                    this.$refs.booking_form.reset()
                    await this.getEvent()
                } catch (e) {
                    console.log(e)
                    this.error_data.status = true
                    this.error_data.msg = 'Cannot Overlap'
                    await this.getEvent()
                }
            },
            showAddDialog(data) {
                if(!this.$store.getters.isLoggedIn){
                    return
                }
                const dateTime = new Date(Date.parse(data.date + ' ' + data.time));
                const end = new Date(dateTime.getTime() + (1 * 60 * 60 * 1000))
                this.add_form_data.start_date = this.roundToNearestFiveMinutes(dateTime)
                this.add_form_data.end_date = this.roundToNearestFiveMinutes(end)
                this.add_dialog = true

            },
            roundToNearestFiveMinutes(date) {
                const coeff = 1000 * 60 * 15; // 5 minutes in milliseconds
                const roundedDate = new Date(Math.round(date.getTime() / coeff) * coeff);
                return roundedDate;
            },
            checkOverlap(start, end) {
                for (let i = 0; i < this.events.length; i++) {
                    let event = this.events[i]
                    const event_start = new Date(event.start)
                    const event_end = new Date(event.end)
                    // console.log(start,'|',event_end)
                    if (start < event_end && end > event_start) {
                        return false
                    }
                }
                return true
            },
            async deleteEvent() {
                try {
                    await window.axios.post('booking/delete', {b_id: this.selectedEvent.id})
                    this.selectedOpen = false
                    await this.getEvent()
                } catch (e) {
                    this.error_data.status = true
                    this.error_data.msg = 'Permission access denied, Please do not delete others'
                }
            },
             updateBooking(item)
            {
                const event_start = new Date(item.start)
                const event_end = new Date(item.end)
                this.update_form_data={
                    b_id: item.id,
                    title: item.name,
                    description: item.details,
                    start_date: event_start,
                    end_date: event_end,

                }
                this.update_dialog=true
                // console.log( this.update_form_data)
            },
            async updateSave(){
                try {
                    if (!this.$refs.update_form.validate()) {
                        return
                    }

                    // const check = this.checkOverlap(this.update_form_data.start_date, this.update_form_data.end_date)
                    //
                    // if (!check) {
                    //     this.error_data.status = true
                    //     this.error_data.msg = 'Event Overlap with others, Please adjust your time'
                    //     return
                    // }

                    console.log(this.update_form_data)
                    await window.axios.post('booking/update', this.update_form_data)
                    this.update_dialog = false
                    // this.$refs.update_form.reset()
                    await this.getEvent()
                }catch (e) {
                    this.error_data.status = true
                    this.error_data.msg = 'Permission access denied / Overlaps'
                    await this.getEvent()
                }
            }
        },

    }
</script>
<style scoped>
    >>>
    .v-present {
        background-color: #FFF3E0;
    }
</style>